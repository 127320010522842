import React from 'react';
import classNames from 'classnames';
import {
  AffiliateSimpleItem,
  Props as AffiliateSimpleItemProps,
} from 'js/components/common/Affiliate/AffiliateSimpleItem';
import { ColumnType } from 'js/types/gridList';

export type Props = {
  list: AffiliateSimpleItemProps[];
  column?: ColumnType;
  isBannerOnly?: boolean;
};

export const AffiliateSimpleListPresenter: React.FC<Props> = ({
  list,
  column = 'auto',
  isBannerOnly,
}) => (
  <ul
    className={classNames(
      'c_contents-group',
      'p_grid-list',
      'p_affiliate-simple__list',
      `column-${column}`,
      'u_bg-white',
    )}
  >
    {list.map((item) => (
      <AffiliateSimpleItem {...item} {...{ isBannerOnly }} key={item.linkUrl} />
    ))}
  </ul>
);
